
.mail {
    color: #ff9f43 !important;

}

.sp {
    color: #ff9f43 !important;
}

.imglogo {
    width: 40%;
    margin: auto;
}

.back {
    background-color: #ff9f43 !important;
}
.error-span{
    font-size: smaller;
    color: #ea5455;
}

.nav.nav-tabs .nav-item .nav-link.active {
    position: relative;
    color: #ff9f43 !important;
    transition: all 0.2s ease;
}

.fotlink {
    padding: 5px;
    color: #ff9f43 !important;
}

.clearfix {
    font-size: 16px;
}

.nav.nav-tabs .nav-item .nav-link.active:after {
    left: 0;
    background: linear-gradient(30deg, #ff9f43, rgba(253, 165, 0, 0.651)) !important;
}

[dir] .form-control:focus {
    border: 1px solid #ff9f43 !important;
}

.dropdown .dropdown-menu .dropdown-item:hover {
    color: #fff !important;
    background-color: #ff9f43 !important;
}

[dir] .badge-primary {
    background-color: #ff9f43 !important;
}

.user-name {
    margin-top: 7px !important;
}

.spinner-border {
    width: 1rem !important;
    height: 1rem !important;
    display: block !important;
    margin: auto !important;
}

.rounded1 {
    border-radius: 50% !important;
}

.example {
    margin-bottom: 20px;
    margin-top: 20%;

    padding: 30px 50px;
    text-align: center;
    border-radius: 4px;
    min-height: 270px;
}

.ant-spin-dot-item {
    background-color: #f2871e !important;
}

.iti__flag {background-image: url("../pages/images/flags.png");}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .iti__flag {background-image: url("../pages/images/flags@2x.png");}
}
.iti__country-list {
    direction: ltr !important;
}
.iti--allow-dropdown .iti__flag-container {
    direction: ltr !important;
}
.iti {
    width: 100% !important;
}
.hide {
    display: none;
}
.valid {
    font-size: smaller;
    margin-top: 0.25rem;
    color: rgba(29, 238, 40, 0.87);
    font-weight: 700;
}
.notvalid {
    font-size: smaller;
    margin-top: 0.25rem;
    color: #ea5455;
    font-weight: 700;
}
.imgstyle {
    min-height: 190px;
}

[dir=rtl] .iti--allow-dropdown input, [dir=rtl] .iti--allow-dropdown input[type=text], [dir=rtl] .iti--allow-dropdown input[type=tel], [dir=rtl] .iti--separate-dial-code input, [dir=rtl] .iti--separate-dial-code input[type=text], [dir=rtl] .iti--separate-dial-code input[type=tel] {
    padding-right: 90px !important;
}
.error-invalid{
     margin-top: 0.25rem;
     display: block;
     width: 100%;
     font-size: smaller;
     color: #ea5455;

}


.redeem {
    color: white;
    background: #009E15 0% 0% no-repeat padding-box !important;
    border: 1px solid #009E15 !important;
    border-radius: 0px 8px 8px 0px !important;
    opacity: 1 !important;
    padding: 6% 6% !important;
    cursor: pointer;
    margin-top: -3px;
    font-size: 14px;
    height: 37px !important;
    min-width: 97px;
}
.redeemVerifyOpactiy {
    background:#7cbd84 0% 0% no-repeat padding-box !important;
    border: 1px solid #7cbd84 !important;
    color: white;
    border-radius: 0px 8px 8px 0px !important;
    opacity: 1 !important;
    padding: 6% 6% !important;
    cursor: pointer;
    margin-top: -3px;
    font-size: 14px;
    height: 37px !important;
    min-width: 97px;
}

.headerVerify{
    font-weight: bold;
    text-align: center;
    font-size: 15px;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}
.bodyVerify{
    text-align: center;
    font-weight: 300;
    font-size: 12px;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.7;
}
.borderNone{
    border: none !important
}
.fontButton{
    font-size: 12.5px !important;
}


.css-ae0g2e{
    margin: auto;
}
body{
    --ReactInputVerificationCode-itemWidth: 4rem;
    --ReactInputVerificationCode-itemHeight: 3rem;
    --ReactInputVerificationCode-itemSpacing :1.2rem;
}
@media (max-width: 500px) {
    body {
        --ReactInputVerificationCode-itemWidth: 2rem ;
        --ReactInputVerificationCode-itemHeight: 2.5rem;
        --ReactInputVerificationCode-itemSpacing: 1.2rem;
    }
}
@media (max-width: 319px) {
    body {
        --ReactInputVerificationCode-itemWidth: 1.5rem ;
        --ReactInputVerificationCode-itemHeight: 2rem;
        --ReactInputVerificationCode-itemSpacing: 1rem;
    }
}
.css-jxah1j {
    color: #F59E19;
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border-radius: 8px !important;
    opacity: 1 !important;
    /*box-shadow: none!important;*/
}
.css-jxah1j.is-active{
    box-shadow:inset 0 0 0 1px #F59E19 !important;
}
.err {
    color: #ea5455 !important;
    font-size: 12px !important;
    text-align: right;
}
.css-1jkbsv7{
    margin: auto !important;
}

.verifyButton {
    margin-top: 0.3% !important;
    margin-inline-start: -1%;

}
.verifyinput{
    flex: 1;
}
[dir=rtl] .iti--allow-dropdown input, [dir=rtl] .iti--allow-dropdown input[type=text], [dir=rtl] .iti--allow-dropdown input[type=tel], [dir=rtl] .iti--separate-dial-code input, [dir=rtl] .iti--separate-dial-code input[type=text], [dir=rtl] .iti--separate-dial-code input[type=tel] {
    padding-left: 2% !important;
}

[dir=rtl] .ReactInputVerificationCode__container{
    direction: ltr !important;
}
